export interface Survey {
    unique_identifier: string;
    slides: Array<
        | InputSlide
        | MultipleChoiceSlide
        | StarSlide
        | NumericSlide
        | NPSSlide
        | TextSlide
    >;
    person?: Person;
    theme: SurveyTheme;
    popup_placement?: SurveyPlacementTypes;
    delay: number;
}

// DIFFERENT TYPES
export interface InputSlide extends Slide {
    type: SlideType.input;
    placeholder: string;
    multiline?: boolean;
}

export interface MultipleChoiceSlide extends Slide {
    type: SlideType.multiplechoice;
    multiple: true;
    options: {label: string}[];
}

export interface StarSlide extends Slide {
    type: SlideType.star;
}

export interface NumericSlide extends Slide {
    type: SlideType.numeric;
    min: number;
    max: number;
}

export interface TextSlide extends Slide {
    type: SlideType.text;
}

export interface NPSSlide extends Slide {
    type: SlideType.nps;
}

// BASE
export interface Slide {
    unique_identifier: string;
    title: string;
    subtitle?: string;
    type: SlideType;
    button_label?: string;
}

export interface SurveyTheme {
    unique_identifier: string;
    name: string;
    title_color: string;
    subtitle_color: string;
    answer_color: string;
    button_color: string;
    button_text_color: string;
    background_color: string;
    popup_border_color: string;
    popup_border_width: number;
    popup_rounded_corners: SurveyThemePopupRoundedCorners;
}

export interface Person {
    style: {
        border_color: string;
        border_width: number;
        type: 'circle' | 'rectangle';
    };
    video_url: string;
    logic: '1-step' | '2-step'; // 1-step: shows person and survey at once | 2-step: shows person first, then survey on click
}

export enum SlideType {
    'input' = 'input',
    'multiplechoice' = 'multiplechoice',
    'star' = 'star',
    'numeric' = 'numeric',
    'nps' = 'nps',
    'text' = 'text',
}

export enum SurveyActions {
    SURVEY_SHOWN = 'survey_shown',
    SURVEY_COMPLETED = 'survey_completed',
    SURVEY_SKIPPED = 'survey_skipped',
}

export enum SurveyPlacementTypes {
    BOTTOM_LEFT = 'bottom_left',
    BOTTOM_RIGHT = 'bottom_right',
    BOTTOM_CENTER = 'bottom_center',
}

export enum SurveyThemePopupRoundedCorners {
    none = 'none',
    small = 'small',
    medium = 'medium',
    large = 'large',
}
